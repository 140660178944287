
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Praktikum und Lehre | Styria Media Group',
      keywords: ['praktikum', 'lehre', 'lehrstelle', 'ferialjob', 'medienunternehmen', 'styria'],
      description: 'Praktikumsplatz oder Lehrstelle gesucht? Jetzt durchstarten – in einem von vielen Lehrberufen und Praktika in der STYRIA',
      image: require('@/assets/img/og/karriere.png'),

    },
    en: {
      title: 'Internships and apprenticeships | Styria Media Group',
      keywords: ['internship', 'apprenticeship', 'summer job', 'media company', 'styria'],
      description: 'Looking for an internship or apprenticeship? Get started now at STYRIA! ',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,

  },
  setup() {
    return {
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
